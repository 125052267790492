<template>
  <div class="operate">
    <div>{{$t('请先选择条件，再查询是否有库存')}}</div>
    <van-form ref="form" input-align="right" error-message-align="right"  @submit.native.prevent>
      <div class="hr">{{$t('查询条件')}}</div>
      <van-field
        name="radio"
        :label="$t('车辆类型')"
        error-message-align="right"
        :value="formData.specialVehicleType"
        input-align="right">
        <template #input>
          <van-radio-group
            v-model="formData.specialVehicleType"
            icon-size="32px"
            class="custom-radio-group-wrap"
          >
            <van-radio v-for="item in list" :key="item.dictCode" :name="item.dictCode" class="custom-radio-wrap">
              <template #icon="props">
                <div class="custom-radio" :class="{ checked: props.checked, typeCheck: props.checked }">{{item.dictName}}</div>
              </template>
            </van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <!-- <van-field
        v-model="formData.deliveryStoreName"
        readonly
        :label="$t('交付门店')">
      </van-field> -->
      <van-field
          v-model="formData.deliveryStoreName"
          input-align="right"
          :label="$t('交付门店')"
          right-icon="arrow"
          readonly
          @click="selectField('deliveryStoreCode', 'deliveryStoreName', false)">
      </van-field>
      <van-field
        :value="formData.seriesNames"
        input-align="right"
        :label="$t('车系')"
        right-icon="arrow"
        readonly
        @click="selectCarPackage('seriesCodes', 'seriesNames', true)">
      </van-field>
      <van-field
        :value="formData.modelNames"
        input-align="right"
        :label="$t('车型')"
        right-icon="arrow"
        readonly
        @click="selectCarPackage('modelCodes', 'modelNames', true)">
      </van-field>
      <van-field
        :value="formData.colourNames"
        input-align="right"
        :label="$t('外饰')"
        right-icon="arrow"
        readonly
        @click="selectCarPackage('colourCodes', 'colourNames', true)">
      </van-field>
      <van-field
        :value="formData.innerColorNames"
        input-align="right"
        :label="$t('内饰')"
        right-icon="arrow"
        readonly
        @click="selectCarPackage('interiorCodes', 'innerColorNames', true)">
      </van-field>      
      <van-field
        :value="formData.optionsNames"
        input-align="right"
        :label="$t('选装')"
        right-icon="arrow"
        readonly
        @click="selectCarPackage('optionsCodes', 'optionsNames', true)">
      </van-field>
      <van-field
          v-model="formData.vehicleNodesName"
          input-align="right"
          :label="$t('车辆节点')"
          right-icon="arrow"
          readonly
          @click="selectCarPackage('vehicleNodes', 'vehicleNodesName', true)">
      </van-field>
      <div v-if="hasData" class="hr">查询结果</div>
      <div v-if="hasData" class="search-result">
        <div>    
          <img v-if="storeList.length > 0" style="width: 120px;" src="./imgs/storeImg.png"/>
          <img v-else style="width: 120px;" src="./imgs/noData.png" />
          <div style="text-align: center">{{ storeList.length > 0 ? '该配置有库存' : '抱歉，该配置无库存'}}</div>
        </div>
      </div>

      <div class="common-footer">
        <van-button v-if="!hasData" class="btn back" native-type="button" @click.stop="goBack">{{$t('返回')}}</van-button>
        <van-button v-if="hasData" class="btn back" native-type="button" @click="reset">重置</van-button>
        <van-button class="btn submit" type="submit" @click="onSubmit">查询库存</van-button>
        <van-button v-if="hasData" class="btn submit" type="submit" @click="create">创建同款配置单</van-button>
      </div>
    </van-form>
  </div>
  </template>
<script>
import selectCarPackage from '@/components/select-car-package'
import commonSelector from '@/components/common-selector'
import { dateFormat } from '@/utils'
import formatParams from '@/utils/formatParams'
import { getWarehouseList } from '@/services/specialCar.js'
import store from '@/store'
import mixin from './mixin'

let vm
export default {
  filters:{
    text:(val)=>{
      const { start,end } =val
      if (!start||!end) return ''
      else return `${dateFormat(start,'YYYY-MM-DD')} ${dateFormat(start,'HH:mm')} ${vm.$t('至')} ${dateFormat(end,'YYYY-MM-DD')} ${dateFormat(end,'HH:mm')}`
    },
    formatCarInfo(info = {}) {
      const { seriesName, modelName } = info
      let display = ''
      seriesName && (display += ` ${seriesName}`)
      modelName && (display += ` ${modelName}`)
      return Object.keys(info).length === 0
        ? null
        : display
    },
    documentFilter(val) {
      const { dictHash } = store.getters
      if (dictHash && dictHash[3002] && Array.isArray(dictHash[3002])) {
        const obj = dictHash[3002].find(({ code }) => code === val)
        if (obj) {
          return obj.name
        }
      } else {
        return ''
      }
      return ''
    },
  },
  mixins: [mixin],
  data(){
    return {
      formData: {
        deliveryStoreCode: [],
        deliveryStoreName: '',
        specialVehicleType: '40551004',
        seriesNames: '',
        seriesCodes: [],
        modelNames: '',
        modelCodes: [],
        colourNames: '',
        colourCodes: [],
        innerColorNames: '',
        interiorCodes: [],
        optionsNames: '',
        optionsCodes: [],
        materialCode: '', // 物料信息
        vehicleNodes: [],
        vehicleNodesName: '',
      },
      hasData: false, // 是否有查询结果
      storeList: [], // 查询出的库存列表
      modelListInfo: [], // 车型车型处理后数据
      outterColorList: [],
      innerColorList: [],
      optionsList: [],
      materialList: [],
    }
  },
  computed:{
    list(){
      return [
        { dictName: '质损车', dictCode: '40551002' },
        { dictName: '老龄车', dictCode: '40551003' },
        { dictName: 'Ni80车型', dictCode: '40551004' },
      ]
    },
    cellOptions(){
      return [
        { dictName: '预入中心库', dictCode: 20221001 },
        { dictName: '预入中转库', dictCode: 20221002 },
        { dictName: '已零售移库', dictCode: 20221003 },
        // { dictName: '已入门店库', dictCode: 20221004 },
        { dictName: '预入门店库', dictCode: 20221005 },
        { dictName: '已入中转库', dictCode: 20221006 },
        { dictName: '已入中心库', dictCode: 20221007 },
        { dictName: '已退库', dictCode: 20221009 },
      ]
    },
    // 交付门店
    deliveryStoreList() {
      let arr = []
      try {
        arr = this.userInfo.dealers[0].relations.map(item => {
          return {
            dictCode: item.dealerCode,
            dictName: item.dealerName
          }
        })

      } catch (error) {
            
      }
      return arr
    },
  },
  async mounted(){
    const { deliveryStoreName, deliveryStoreCode } = this.$route.query
    this.formData.deliveryStoreCode = deliveryStoreCode
    this.formData.deliveryStoreName = deliveryStoreName
    this.getMaterialList()
  },
  created(){
    vm = this
  },
  methods:{
    goBack(){
      this.$router.go(-1)
    },
    selectField(field, dictType, multiple = false) {
      const _obj = {
        field,
        dictType,
        multiple,
        lastSelect: this.formData[field],
        cellOptions: this.deliveryStoreList
      }
      commonSelector(_obj).then(res => {
        this.formData[field] = res.dictCode
        this.formData[dictType] = res.dictName
        if (field === 'deliveryStoreCode') {
          this.formData[field] = [res.dictCode]
        }
      })
    },
    async selectCarPackage(field, name) {
      const obj = {
        'seriesCodes': this.seriesListDict,
        'modelCodes': this.modelListDict,
        'colourCodes': this.outsideColoursDict,
        'interiorCodes': this.innerColoursDict,
        'optionsCodes': this.saleOptionsDict,
        'vehicleNodes': this.cellOptions,
      }
      selectCarPackage({ initOptions: this.formData[field], requestList: obj[field] }).then(res => {
        this.formData[field] = res.map(item => { return item.dictCode })
        this.formData[name] = res.map(item => { return item.dictName }).join(',')
        if (field === 'seriesCodes') {
          this.formData.modelNames = ''
          this.formData.modelCodes = []
          this.formData.colourNames = ''
          this.formData.colourCodes = []
          this.formData.innerColorNames = ''
          this.formData.interiorCodes = []
          this.formData.optionsNames = ''
          this.formData.optionsCodes = []
          this.controlModelSales(2, formatParams(this.formData, false))
        }
        if (field === 'modelCodes') {
          this.formData.colourNames = ''
          this.formData.colourCodes = []
          this.formData.innerColorNames = ''
          this.formData.interiorCodes = []
          this.formData.optionsNames = ''
          this.formData.optionsCodes = []
          this.controlModelSales(3, formatParams(this.formData, false))
        }
        if (field === 'colourCodes') {
          this.formData.innerColorNames = ''
          this.formData.interiorCodes = []
          this.formData.optionsNames = ''
          this.formData.optionsCodes = []
          this.controlModelSales(4, formatParams(this.formData, false))
        }
        if (field === 'interiorCodes') {
          this.formData.optionsNames = ''
          this.formData.optionsCodes = []
          this.controlModelSales(5, formatParams(this.formData, false))
        }
      })
    },
    reset() {  
      const { deliveryStoreName, deliveryStoreCode } = this.$route.query
      this.formData = {
        deliveryStoreCode: deliveryStoreCode,
        deliveryStoreName,
        specialVehicleType: '40551004',
        seriesNames: '',
        seriesCodes: [],
        modelNames: '',
        modelCodes: [],
        colourNames: '',
        colourCodes: [],
        innerColorNames: '',
        interiorCodes: [],
        optionsNames: '',
        optionsCodes: [],
        materialCode: '', // 物料信息
        vehicleNodes: [],
        vehicleNodesName: '',
      }
      this.hasData = false
      this.storeList = []
    },
    async onSubmit(){
      try {
        const params = {
          queryType: 1, // 查询类型 0门店库存 1中心库库存
          specialVehicleTypes: Array.isArray(this.formData.specialVehicleType) ? this.formData.specialVehicleType : [this.formData.specialVehicleType],
          deliveryStoreCodes: Array.isArray(this.formData.deliveryStoreCode) ? this.formData.deliveryStoreCode : [this.formData.deliveryStoreCode],
          seriesCodes: this.formData.seriesCodes,
          modelCodes: this.formData.modelCodes,
          colourCodes: this.formData.colourCodes,
          interiorCodes: this.formData.interiorCodes,
          optionsCodes: this.formData.optionsCodes,
          vehicleNodes: this.formData.vehicleNodes
        }
        await this.$refs.form.validate()
        const res = await getWarehouseList(params)
        this.hasData = res
        this.storeList = res.dataList
        // this.goBack()
      } catch (error) {
        console.log(this.$t('报错了'))
      }
    },
    create(){ 
      // 点击后校验上方配置是否选择完成 （足以组成一个物料代码）
      const { seriesCodes, modelCodes, colourCodes, interiorCodes, optionsCodes } = this.formData
      if (seriesCodes.length === 0 || modelCodes.length === 0 || colourCodes.length === 0 || interiorCodes.length === 0) {
        return this.$toast('请选择完整的车辆配置，才可创建配置单')
      } else {
        // 如果没有选装，有且仅有一条物料时可创建配置单
        if (this.saleOptionsDict.length === 0) {
          const materialCodeList = this.materialList.filter(item=> {
            return seriesCodes.includes(item.seriesCode) && modelCodes.includes(item.modelCode) && colourCodes.includes(item.colourCode) && interiorCodes.includes(item.interiorCode)
          })
          if (materialCodeList.length > 1) {
            return this.$toast('当前选择不能确定唯一物料代码，请重新选择')
          } else if (materialCodeList.length === 1) {
            // console.log('没有选装，有且仅有一条物料', materialCodeList)
            this.formData.materialCode = materialCodeList[0].materialCode
            this.goDetail(materialCodeList[0])
          } else {
            return this.$toast('请选择完整的车辆配置，才可创建配置单')
          }
        } else {
          // 有选装但是未选择，则对比物料列表是否有对应唯一物料代码的数据，有则下单
          if (optionsCodes.length === 0) {
            const materialList = this.materialList.filter(item=> {
              if ((this.formData.seriesCodes.length > 0 ? this.formData.seriesCodes.includes(item.seriesCode) : true) && 
              (this.formData.modelCodes.length > 0 ? this.formData.modelCodes.includes(item.modelCode) : true) && 
              (this.formData.colourCodes.length > 0 ? this.formData.colourCodes.includes(item.colourCode) : true) && 
              (this.formData.interiorCodes.length > 0 ? this.formData.interiorCodes.includes(item.interiorCode) : true)) {
                return !item.optionList || item.optionList.length === 0
              }
            })
            if (materialList.length > 1) {
              return this.$toast('当前选择不能确定唯一物料代码，请重新选择')
            } else if (materialList.length === 1) {
              this.formData.materialCode = materialList[0].materialCode
              this.goDetail(materialList[0])
            } else {
              return this.$toast('请选择完整的车辆配置，才可创建配置单')
            }
          } else {
            // 有选装列表并且选择了 选装包凑成一个物料代码
            const flagList = this.materialList.map((item) => {
              let codeList = []
              if (item.optionList.length > 0 && 
              (this.formData.seriesCodes.length > 0 ? this.formData.seriesCodes.includes(item.seriesCode) : true) && 
              (this.formData.modelCodes.length > 0 ? this.formData.modelCodes.includes(item.modelCode) : true) && 
              (this.formData.colourCodes.length > 0 ? this.formData.colourCodes.includes(item.colourCode) : true) && 
              (this.formData.interiorCodes.length > 0 ? this.formData.interiorCodes.includes(item.interiorCode) : true)) {
                codeList = item.optionList.map(item2 => {
                  return item2.optionsCode
                })
              }
              const sameEleList = this.filterArr(codeList, optionsCodes)
              // return codeList.length === sameEleList.length && sameEleList.length !== 0 && codeList.findIndex((v, i) => v !== sameEleList[i]) === -1
              return optionsCodes.length === sameEleList.length && sameEleList.length !== 0 && codeList.findIndex((v, i) => v !== sameEleList[i]) === -1
            })
            // 选择一组物料代码的选装
            if (flagList.filter(e => e === true).length !== 1) {
              return this.$toast('当前选装包不能确定唯一物料代码，请重新选择')
            } else {
              const data = this.materialList[flagList.findIndex(item => item === true)]
              this.goDetail(data)
            }
          }
        }
      }
    },
    goDetail(data) {
      const { deliveryStoreCode, deliveryStoreName, specialVehicleType } = this.formData
      const obj = { ...data, deliveryStoreCode, deliveryStoreName, specialVehicleType }
      sessionStorage.setItem('specialOrderConfig', JSON.stringify(obj)) 
      this.$router.push({
        path: '/special-car-stock/detail',
        query: {
          entryType: 'operate'
        }
      })
    },
  }
}
</script>
    <style lang="less" scoped>
      @import "@/modules/clue/style/commonless.less";
    
      .operate{
        padding: 16px;
      }
      /deep/ .custom-radio-group-wrap {
        margin-top: -10px;
        display: flex;
        flex-wrap: wrap;
        .custom-radio-wrap {
          margin-top: 10px;
          margin-left: 12px;
          .custom-radio {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 8px;
            height: 24px;
            color: rgba(13,23,26,0.45);
            background-color: #F5F5F5;
            border-radius: 3px;
            font-size: 12px;
            // &.checked {
            //   // border: 1px solid @yellow;
            //   // color: @yellow-text
            // }
            &.typeCheck{
              color: #0D171A;
              background: #EED484;
            }
          }
        }
      }
      .hr {
        margin: 4px 0 2px 0;
        display: flex;
        align-items: center;
        &::before {
          content: ' ';
          background-color: #B9921A;
          width: 3px;
          height: 12px;
          display: inline-block;
          margin-right: 10px;
        }
      }
      .search-result {
        font-size: 16px;
        display: flex;
        justify-content: center;
        background-color: #ffffff;
        padding: 6px 0 16px;
      }
    </style>
    